// import '@fortawesome/fontawesome-svg-core/styles.css';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import {
  faDev,
  faLinkedin,
  faGitAlt,
  faGithub,
  faTwitter,
  faFacebook,
  faReddit,
  faJs,
  faHtml5,
  faCss3Alt,
  faJsSquare,
  faReact,
  faInstagram,
  faBehance,
  faDiscord,
  faGooglePlay,
  faFigma,
} from '@fortawesome/free-brands-svg-icons';
import {
  faDesktop,
  faMobile,
  faBezierCurve,
  faPenNib,
  faEye,
  faPaintBrush,
  faWindowMaximize,
  faHandshake,
  faPaperPlane,
  faChevronRight,
  faChevronLeft,
  faClock,
  faCalendarAlt,
  faBars,
  faTimes,
  faMoon,
  faSun,
  faEdit,
  faObjectGroup,
  faTerminal,
  faEnvelope,
  faLink,
  faCopy,
  faTabletAlt,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTabletAlt,
  faDesktop,
  faCopy,
  faLink,
  faMobile,
  faBezierCurve,
  faPenNib,
  faEye,
  faDev,
  faDiscord,
  faGitAlt,
  faGithub,
  faLinkedin,
  faFigma,
  faTwitter,
  faFacebook,
  faReddit,
  faPaintBrush,
  faWindowMaximize,
  faJs,
  faHandshake,
  faPaperPlane,
  faHtml5,
  faChevronRight,
  faChevronLeft,
  faClock,
  faCalendarAlt,
  faReact,
  faBars,
  faTimes,
  faMoon,
  faSun,
  faEdit,
  faObjectGroup,
  faTerminal,
  faInstagram,
  faBehance,
  faCss3Alt,
  faJsSquare,
  faEnvelope,
  faGooglePlay
);
config.autoAddCss = true;
